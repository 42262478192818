<template>
    <div class="save-button">
        <img
            :src="require(`@assets/img/icons/save-${ memod.is_saved ? 'full' : 'empty' }.svg`)"
            class="save"
            alt="Save"
            @click="handleBoardsDropdown"
        >
        <v-popover
            :open="showBoardsDropdown"
            popover-class="boards-list"
            @auto-hide="showBoardsDropdown = false"
        >
            <template slot="popover">
                <div class="popover-option" @click="createNewBoard">
                    <span>Save to new Board</span>
                    <img src="@assets/img/icons/add.svg" alt="Add board">
                </div>
                <hr>
                <boards-list v-if="showBoardsDropdown" :memod="memod" @toggled-save="(is_saved) => $emit('toggled-save', is_saved)" />
            </template>
        </v-popover>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "SaveButton",
    components: {
        BoardsList: () => import(/* webpackChunkName: "boards-list" */ "./boards-list")
    },
    props: {
        memod: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            showBoardsDropdown: false
        };
    },
    computed: {
        ...mapGetters({
            isLoggedIn: "User/isLoggedIn"
        })
    },
    methods: {
        handleBoardsDropdown() {
            if (!this.isLoggedIn) {
                this.$modal.show("auth-modal", { allowClose: true, showSignIn: true });
            } else {
                this.showBoardsDropdown = !this.showBoardsDropdown;
            }
        },
        createNewBoard() {
            this.$modal.show("create-board-modal", { memoToSaveId: this.memod.id });
            this.showBoardsDropdown = false;
        }
    }
}
</script>

<style lang="scss" scoped>
.save-button {
    cursor: pointer;
    position: relative;

    .v-popover {
        position: absolute;
        left: 50%;
    }
}
</style>

<style lang="scss">
.loading-more-boards {
    background-color: #1C1C1E;
}

.boards-list {
    .popover-options {
        .popover-option {
            span.is_inside {
                color: $primary-color;
            }
        }
    }
}
</style>
